<template>
  <div id="register">
    <section class="site-section">
      <div class="container">
        <div class="section-body">
          <div class="row justify-content-center">
            <div class="col-md-6 col-12">
              <RegisterComponent title="פתיחת חשבון חדש" :isRegister="true" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import RegisterComponent from '@/components/auth/RegisterComponent'
export default {
  name: 'Register',

  components: {
    RegisterComponent
  },

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME + ' | הרשמה | ' + process.env.VUE_APP_NAME_HEB
    }
  },
}
</script>
